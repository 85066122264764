import { useEffect } from "react";
import Header from "./Header";
import { useSharedState } from "./ImageProvider";
import "./App.css";
import React from "react";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";

const Booking = () => {
  const { selectedSrc, updateSelectedSrc } = useSharedState();
  const [clickedDiv, setClickedDiv] = React.useState(null);
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [countdown, setCountdown] = React.useState(120);
  const [countdownActive, setCountdownActive] = React.useState(false);
  const location = useLocation();
  const [showModal, setShowModal] = React.useState(false);

  useEffect(() => {
    changeRandomDivColor();
  }, [location.pathname]);

  const getRandomNumber = (min: any, max: any) => {
    return Math.floor(Math.random() * (max - min) + min);
  };

  const changeRandomDivColor = () => {
    const divs = document.querySelectorAll('[data-id="seat"]');
    const numberOfDivsToChange = getRandomNumber(1, divs.length + 1);

    for (let i = 0; i < numberOfDivsToChange; i++) {
      const randomIndex = getRandomNumber(0, divs.length);
      const randomDiv = divs[randomIndex] as HTMLElement;
      randomDiv.style.backgroundColor = "#393939";
      randomDiv.style.pointerEvents = "none";
    }
  };

  const handleGeneralClick = (event: any) => {
    event.target.classList.toggle("bg-red-600");
  };

  const handlePurchaseClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };
  let activeDivCount: number = 0;
  const calculatePrice = () => {
    return 300;
  };

  const handleSeatClick = (event: any) => {
    const clickedIndex = Number(event.target.getAttribute("data-index"));
    event.target.classList.toggle("bg-red-600");

    const selectedDivs = document.querySelectorAll(".bg-red-600");

    if (selectedDivs.length === 1 && !countdownActive) {
      startCountdown();
    } else if (selectedDivs.length === 0) {
      resetCountdown();
    }

    setTotalPrice(
      Array.from(selectedDivs).reduce((total, div) => {
        const index = Number(div.getAttribute("data-index"));
        return total + calculatePrice();
      }, 0)
    );
  };

  const startCountdown = () => {
    setCountdownActive(true);
  };

  const resetCountdown = () => {
    setCountdownActive(false);
    setCountdown(120);
  };

  const handleFinalPurchase = () => {
    const name = document.getElementById("name") as HTMLInputElement | null;
    const email = document.getElementById("email") as HTMLInputElement | null;

    const cellPhone = document.getElementById(
      "cell-phone"
    ) as HTMLInputElement | null;

    if (
      !name ||
      !email ||
      !cellPhone ||
      !name.value.trim() ||
      !email.value.trim() ||
      !cellPhone.value.trim()
    ) {
      alert("Please fill out all required fields.");
    } else {
      handleClose();
      alert("Your Details has been forwarded to FBI! LOL get L'ed");
    }
  };

  useEffect(() => {
    let countdownInterval: NodeJS.Timeout;

    if (countdownActive) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            resetCountdown();
          }

          return prevCountdown - 1;
        });
      }, 1000);
    }

    return () => clearInterval(countdownInterval);
  }, [countdownActive]);

  useEffect(() => {
    console.log("Total Price:", totalPrice);
  }, [totalPrice]);
  

  return (
    <div className="w-screen h-full flex md:flex-col overflow-clip">
      <Header />
      <div className="w-[45vw] h-[780px] relative -z-1 md:w-full md:h-[400px]">
        <img className="w-full h-full object-cover" src={selectedSrc} alt="" />
      </div>
      <div className="w-[55vw] h-auto bg-black flex flex-col items-center md:w-full md:h-[800px]">
        <div className="w-1/2 border-b-[1px] h-[140px] flex flex-col items-center relative top-5">
          <h2 className="text-white font-sans text-xl">Date</h2>
          <div className="dates w-full h-[70px] overflow-x-auto flex gap-6 relative top-4 items-center">
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              1
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              2
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              3
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              4
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              5
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              6
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              7
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              8
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              9
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              10
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              11
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              12
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              13
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              14
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              15
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              16
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              17
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              18
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              19
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              20
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              21
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              22
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              23
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              24
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              25
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              26
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              27
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              28
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              29
            </div>
            <div
              onClick={handleGeneralClick}
              className="min-w-7 h-10 flex justify-center items-center bg-[#2B2B2B] text-white font-sans rounded-t-full rounded-b-full"
            >
              30
            </div>
          </div>
        </div>
        <div className="relative top-10 border-b-[1px] flex flex-col items-center w-[500px] h-[120px] md:overflow-scroll">
          <h2 className="text-white font-sans text-xl">Time</h2>
          <div className="time flex gap-5 text-white relative top-5 md:gap-0">
            <div
              onClick={handleGeneralClick}
              className="w-[80px] h-7 text white flex items-center justify-center bg-[#2B2B2B] rounded-full"
            >
              13:15
            </div>
            <div
              onClick={handleGeneralClick}
              className="w-[80px] h-7 text white flex items-center justify-center bg-[#2b2b2b] rounded-full"
            >
              15:15
            </div>
            <div
              onClick={handleGeneralClick}
              className="w-[80px] h-7 text white flex items-center justify-center bg-[#2B2B2B] rounded-full"
            >
              18:15
            </div>
            <div
              onClick={handleGeneralClick}
              className="w-[80px] h-7 text white flex items-center justify-center bg-[#2B2B2B] rounded-full"
            >
              20:30
            </div>
            <div
              onClick={handleGeneralClick}
              className="w-[80px] h-7 text white flex items-center justify-center bg-[#2B2B2B] rounded-full"
            >
              22:30
            </div>
          </div>
        </div>
        <div className="relative text-white flex font-sans top-14 -left-5 gap-14">
          <p>
            Total: <i className="fa-solid fa-indian-rupee-sign text-white"></i>
            {totalPrice.toFixed(2)}
          </p>
        </div>
        <div className="w-3/4 relative top-16 h-[330px] flex flex-col items-center sm:overflow-scroll">
          <div className="w-full h-[150px]">
            <img
              className="w-full h-full object-contain relative"
              src="./images/LIGHT.svg"
              alt=""
            />
          </div>
          <div className="seats flex gap-3 relative -top-20 ">
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={` w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
          </div>
          <div className="seats flex gap-3 relative -top-[70px]">
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
          </div>
          <div className="seats flex gap-3 relative -top-[60px]">
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
          </div>
          <div className="seats flex gap-3 relative -top-[50px]">
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
          </div>
          <div className="seats flex gap-3 relative -top-[40px]">
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
          </div>
          <div className="seats flex gap-3 relative -top-[30px]">
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
          </div>
          <div className="seats flex gap-3 relative -top-[20px]">
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
            <div
              data-id="seat"
              onClick={handleSeatClick}
              className={`w-[18px] h-[18px] rounded-full ${
                clickedDiv === "seat" ? "bg-red-600" : "bg-[#a4a4a4]"
              } `}
            ></div>
          </div>
          <div className="flex gap-3 relative text-white cursor-pointer">
            <div className="w-[18px] h-[18px] text-center">1</div>
            <div className="w-[18px] h-[18px] text-center">2</div>
            <div className="w-[18px] h-[18px] text-center">3</div>
            <div className="w-[18px] h-[18px] text-center">4</div>
            <div className="w-[18px] h-[18px] text-center">5</div>
            <div className="w-[18px] h-[18px] text-center">6</div>
            <div className="w-[18px] h-[18px] text-center">7</div>
            <div className="w-[18px] h-[18px] text-center">8</div>
            <div className="w-[18px] h-[18px] text-center">9</div>
            <div className="w-[18px] h-[18px] text-center">10</div>
            <div className="w-[18px] h-[18px] text-center">11</div>
            <div className="w-[18px] h-[18px] text-center">12</div>
          </div>
          <div className="flex flex-col text-white relative gap-[4.5px] -top-[230px] -left-[220px] cursor-pointer xs:-left-[200px] xls:-left-[190px]">
            <div>A</div>
            <div>B</div>
            <div>C</div>
            <div>D</div>
            <div>E</div>
            <div>F</div>
            <div>G</div>
          </div>
          <div className="flex w-3/4 justify-evenly relative -top-[160px] text-white xs:w-5/6 xs:justify-between">
            <div className="flex items-center gap-2 xs:text-sm">
              <div className="w-[18px] h-[18px] rounded-full bg-slate-300"></div>
              Available
            </div>
            <div className="flex items-center gap-2 xs:text-sm">
              <div className="w-[18px] h-[18px] rounded-full bg-[#393939]"></div>
              Booked
            </div>
            <div className="flex items-center gap-2 xs:text-sm">
              <div className="w-[18px] h-[18px] rounded-full bg-red-700"></div>
              Selected
            </div>
          </div>
          <button
            onClick={handlePurchaseClick}
            className="relative -top-36 bg-red-600 rounded-full px-3 py-2 text-white font-sans font-semibold"
          >
            Purchase
          </button>
          <Modal
            show={showModal}
            onHide={handleClose}
            dialogClassName="modal-lg modal-centered"
          >
            <Modal.Header closeButton className="flex">
              <Modal.Title className="flex-1 d-flex justify-content-center custom-title">
                Order
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="flex justify-center">
              <form action="submit" className="w-5/6 flex flex-col gap-y-1">
                <label htmlFor="name" className="font-semibold font-sans">
                  Name and Surname
                </label>
                <input
                  type="text"
                  id="name"
                  placeholder=""
                  className=" h-[38px] rounded-lg border-2 font-sans pl-2"
                />
                <label htmlFor="email" className="font-sans font-semibold ">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className=" h-[38px] rounded-lg border-2 font-sans pl-2"
                />
                <label htmlFor="promo" className="font-semibold font-sans">
                  Promo
                </label>
                <div className="flex justify-between">
                  <input
                    type="text"
                    id="promo"
                    placeholder=""
                    className=" h-[38px] w-[70%] rounded-lg border-2 font-sans pl-2"
                  />
                  <button
                    type="submit"
                    className="w-[25%] h[38px] rounded-lg border-2 font-sans font-semibold text-lg hover:bg-slate-600 duration-200 ease-in-out hover:text-white"
                  >
                    Apply
                  </button>
                </div>
                <label htmlFor="cell-phone" className="font-semibold font-sans">
                  Cell.phone
                </label>
                <div className="flex justify-between">
                  <input
                    type="text"
                    name=""
                    id=""
                    className="w-[15%] h-[38px] rounded-lg border-2 text-center cursor-pointer pointer-events-none"
                    value={`+977`}
                  />
                  <input
                    type="Number"
                    name=""
                    id="cell-phone"
                    className="w-[80%] h-[38px] rounded-lg border-2 text-center appearance-none font-sans pl-2"
                    style={{
                      WebkitAppearance: "none",
                      margin: 0,
                      MozAppearance: "textfield",
                    }}
                  />
                </div>
                <label htmlFor="payment" className="font-semibold font-sans">
                  Payment
                </label>
                <div className="flex justify-evenly">
                  <div className="h-[40px] w-[15%] border-2 border-black rounded-full overflow-clip hover:scale-110 duration-200 ease-in-out">
                    <img
                      className="w-full h-full object-cover xs:object-contain"
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Esewa_logo.webp/1200px-Esewa_logo.webp.png"
                      alt=""
                    />
                  </div>
                  <div className="h-[40px] w-[15%] border-2 border-black rounded-full overflow-clip hover:scale-110 duration-200 ease-in-out">
                    <img
                      className="w-full h-full object-cover xs:object-contain"
                      src="https://web.khalti.com/static/img/logo1.png"
                      alt=""
                    />
                  </div>
                  <div className="h-[40px] w-[15%] border-2 border-black rounded-full overflow-clip hover:scale-110 duration-200 ease-in-out">
                    <img
                      className="w-full h-full object-cover xs:object-contain"
                      src="https://www.newbusinessage.com/img/news/20180531010749_IME-Pay-Logo_white%20(2).jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex gap-x-2 mt-3 self-center">
                  <input type="checkbox" name="" id="" />
                  <label
                    htmlFor="check-boxy"
                    className=" font-sans text-[#585353]"
                  >
                    Remember my details(sus)
                  </label>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer className="flex-1 d-flex justify-content-center">
              <button
                onClick={handleFinalPurchase}
                className="rounded-full bg-red-600 px-10 py-2 text-white font-sans font-semibold hover:bg-red-500 duration-200 ease-in-out"
              >
                Purchase{" "}
                <i className="fa-solid fa-indian-rupee-sign text-white pr-1"></i>
                {totalPrice.toFixed(2)}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Booking;
