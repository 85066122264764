import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header className="w-screen h-[50px] absolute z-10 flex items-center bg-transparent font-sans justify-between">
      <h2 className="relative left-20 top-5 text-white/75 text-lg font-sans cursor-pointer">
        <Link to="/Home">
          <i className="fa-brands fa-atlassian text-amber-400 text-2xl"></i>
        </Link>
        Moye Moye <br /> Cinemas
      </h2>
      <div className="relative w-[18px] h-[18px] right-10 hover:p-4 duration-200 ease-in-out hover:rounded-full hover:bg-black text-center flex items-center justify-center ">
        <i className="fa-regular fa-user text-white "></i>
      </div>
    </header>
  );
}
