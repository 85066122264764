const Footer = () => {
  return (
    <footer className="w-screen h-[320px] bg-[#FAFAFA] mt-40 flex justify-center items-center">
      <div className=" w-3/4 bg-inherit flex flex-col justify-center items-center border-b-[1px] pb-10 sm:w-5/6">
        <div className=" uppercase flex justify-between w-full font-sans font-medium sm:text-sm xls:text-xs ">
          <h3 className="text-base xls:text-xs sm:text-sm">Profile</h3>
          <h3 className="text-base xls:text-xs sm:text-sm">Recent posts</h3>
          <h3 className="text-base xls:text-xs sm:text-sm">Customer</h3>
          <h3 className="text-base xls:text-xs sm:text-sm">Contact</h3>
        </div>
        <div className="flex w-full justify-between font-light font-sans mt-2 text-[#3e3e3e] text-sm leading-7 sm:text-xs">
          <div className="flex flex-col font-medium">
            <p>FAQ's</p>
            <p>Pricing Plans</p>
            <p>Order tracking</p>
            <p>Returns</p>
          </div>
          <div className="flex flex-col relative">
            <p>Touch of uniqueness</p>
            <p>Offices you won't forget</p>
            <p>Cicilan</p>
          </div>
          <div className="flex flex-col relative">
            <p>Help & Contact Us</p>
            <p>Return</p>
            <p>Online stores</p>
            <p>Terms & condition</p>
          </div>
          <div className="flex items-center justify-between h-3/4 text-xl text-[#3e3e3e] w-[80px]">
            <i className="fa-brands fa-instagram"></i>
            <i className="fa-brands fa-facebook"></i>
            <i className="fa-brands fa-twitter"></i>
          </div>
        </div>
      </div>
    </footer>
  );
};


export default Footer;
