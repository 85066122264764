import { useEffect, useState } from "react";

interface News {
  title: string;
  description: string;
  url: string;
  urlToImage: string;
  publishedAt: string;
}

const MovieNoose = () => {
  const [news, setNews] = useState<News[]>([]);

  const fetchNews = async () => {
    try {
      const response = await fetch(
        "https://newsapi.org/v2/everything?q=movies&from=2023-12-20&sortBy=publishedAt&apiKey=df177e1018d841059481a4493b27929a"
      );
      const data = await response.json();
      setNews(data.articles);
    } catch (error: any) {
      throw new Error("Error Fetching News");
    }
  };
  useEffect(() => {
    fetchNews();
  }, []);
  return (
    <section className="relative top-32 w-screen h-[540px] shadow-sm md:h-auto">
      <div className="flex w-5/6 h-full mx-auto justify-between items-center md:block">
        <div className="w-[45%] font-sans h-[80%] md:w-full">
          <h2 className="relative text-xl md:mb-2">Latest News</h2>
          {news?.length > 1 && (
            <div className="w-full h-full relative text-white overflow-y-scroll rounded-lg">
              <img
                className="w-full h-full object-cover filter brightness-90"
                src={news[1].urlToImage}
                alt={news[1].title}
              />
              <div className="w-5/6 absolute bottom-12 left-5">
                <h2 className="text-xl font-semibold relative -top-5 xs:text-xs">
                  {news[1].title}
                </h2>
                <p className="xs:text-xs">
                  {news[1].description}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="w-[45%] h-[90%] grid grid-rows-4 gap-y-7  md:w-full md:h-[20%] md:relative md:mt-5">
          {news?.slice(2, 6).map((article, index) => (
            <div key={index} className="flex items-center justify-between md:w-full">
              <div className="h-full w-[100px] rounded-lg overflow-clip hover:scale-110 duration-200 ease-in-out md:w-[200px] md:h-3/4 sm:w-[140px] xls:w-[100px] xls:h-1/2">
                <img
                  className="w-full h-full object-cover"
                  src={article.urlToImage}
                  alt=""
                />
              </div>
              <div className="text-sm text-[#7d7d7d] overflow-scroll w-[60%] h-full xs:text-xs flex flex-col justify-center">
                <p className="text-black font-light relative top-5 text-sm">
                  {article.publishedAt ? article.publishedAt.split("T")[0] : 'N/A'}
                </p>
                <p className="font-semibold font-sans pt-2">
                  {article.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MovieNoose;
