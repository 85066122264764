// SharedStateContext.tsx
import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface SharedStateContextProps {
  selectedSrc: string;
  updateSelectedSrc: Dispatch<SetStateAction<string>>;
}

const SharedStateContext = createContext<SharedStateContextProps | undefined>(undefined);

export const useSharedState = () => {
  const context = useContext(SharedStateContext);
  if (!context) {
    throw new Error('useSharedState must be used within a SharedStateProvider');
  }
  return context;
};

interface SharedStateProviderProps {
  children: ReactNode;
}

export const SharedStateProvider: React.FC<SharedStateProviderProps> = ({ children }) => {
  const [selectedSrc, updateSelectedSrc] = useState<string>('');

  const contextValue: SharedStateContextProps = {
    selectedSrc,
    updateSelectedSrc,
  };

  return (
    <SharedStateContext.Provider value={contextValue}>
      {children}
    </SharedStateContext.Provider>
  );
};
