export default [
    {
        id: 1,
        img: 'https://resizing.flixster.com/-XZAfHZM39UwaGJIFWKAE8fS0ak=/v3/t/assets/p10701949_b_v9_ah.jpg',
        title: 'Shingeki No Kyojin',
        lang: 'SUB',
        date: 'April 7, 2013'
    },
    {
        id: 2,
        img: 'https://demonslayer-anime.com/assets_portal/img/main/img_katanakaji4.jpg',
        title: 'Kimetsu No Yaiba',
        lang: 'SUB',
        date: 'February 3, 2023'
    },
    {
        id: 3,
        img: 'https://m.media-amazon.com/images/M/MV5BYWMwYmYwNjMtMmE0MC00ODdhLTgzYzQtYzA3MDQ5OWRmZWNhXkEyXkFqcGdeQXVyMTUzMTg2ODkz._V1_.jpg',
        title: 'Solo Levelling',
        lang: 'SUB',
        date: 'January 6, 2024'
    },
    {
        id: 4,
        img: 'https://upload.wikimedia.org/wikipedia/en/7/74/Code_Geass_R1_box_set_cover.jpg',
        title: 'Code Geass',
        lang: 'SUB',
        date: 'October 5, 2006'
    },
    {
        id: 5,
        img: 'https://m.media-amazon.com/images/M/MV5BNjRiNmNjMmMtN2U2Yi00ODgxLTk3OTMtMmI1MTI1NjYyZTEzXkEyXkFqcGdeQXVyNjAwNDUxODI@._V1_FMjpg_UX1000_.jpg',
        title: 'Death Note',
        lang: 'SUB',
        date: 'October 4, 2006'
    },
    {
        id: 6,
        img: 'https://m.media-amazon.com/images/M/MV5BZTNlYmU1MmItMGFiNi00MjUxLThlOWMtMzgxMzIyNzk3ODgwXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_FMjpg_UX1000_.jpg',
        title: 'Owari No Seraph',
        lang: 'SUB',
        date: 'April 4, 2015'
    },
    {
        id: 7,
        img: 'https://m.media-amazon.com/images/M/MV5BMjYxZjFkNTEtZjYzNC00MTdhLThiM2ItYmRiOWJiYTJkYzMxXkEyXkFqcGdeQXVyNDQxNjcxNQ@@._V1_FMjpg_UX1000_.jpg',
        title: 'Overlord',
        lang: 'SUB',
        date: 'July 7, 2015'
    },
    {
        id: 8,
        img: 'https://m.media-amazon.com/images/M/MV5BZjJlNzE5YzEtYzQwYS00NTBjLTk5YzAtYzUwOWQyM2E3OGI2XkEyXkFqcGdeQXVyNTgyNTA4MjM@._V1_.jpg',
        title: 'One Punch Man',
        lang: 'SUB',
        date: 'December 2015'
    },
    {
        id: 9,
        img: 'https://m.media-amazon.com/images/M/MV5BMGU1NzE1Y2UtZmZkOC00ODQ4LTkwNDAtNmE4Yzk3NTA4MThlXkEyXkFqcGdeQXVyMzgxODM4NjM@._V1_FMjpg_UX1000_.jpg',
        title: 'Eminem in Shadow',
        lang: 'SUB',
        date: 'October 5, 2022'
    },
    {
        id: 9,
        img: 'https://upload.wikimedia.org/wikipedia/en/4/41/TrigunStampedeKeyvisual.jpg',
        title: 'Trigun Stampede',
        lang: 'SUB',
        date: 'January 7, 2023'
    },
    {
        id: 9,
        img: 'https://m.media-amazon.com/images/M/MV5BMjg3M2U1ZDQtYjJjOS00NWQyLTliNzQtZjQwN2YzMzEzNzBjXkEyXkFqcGdeQXVyNzI3NjY3NjQ@._V1_FMjpg_UX1000_.jpg',
        title: 'Ousama Ranking',
        lang: 'SUB',
        date: 'October 2021'
    },
]