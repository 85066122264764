import Footer from "./Footer";
import Header from "./Header";
import Hero from "./Hero";

export default function Home() {
  return (
    <div>
        <Header />
        <Hero />
    </div>
  );
}
