import { Infinity, Target } from "phosphor-react";
import React from "react";
import { useEffect } from "react";
import "./App.css";
import { useSharedState } from "./ImageProvider";
import { Link } from "react-router-dom";
import { click } from "@testing-library/user-event/dist/click";
import Anime from "./Anime";
import Animation from "./Animation";
import Footer from "./Footer";
import About from "./About";
import MovieNoose from "./MovieNoose";

const Hero = () => {
  const [backgroundImage, setBackgroundImage] = React.useState<string>(
    "https://www.figma.com/file/VQW4OWMDpW4QAqgvLsvjh6/image/b2f9be30f2d248e4d85a22299f4c0eab87c159e5"
  );
  const [imageChanged, setImageChanged] = React.useState(false);
  const { selectedSrc, updateSelectedSrc } = useSharedState();

  const handleChange = (imageSrc: string) => {
    setBackgroundImage(imageSrc);
    setImageChanged(true);

    setTimeout(() => {
      setImageChanged(false);
    }, 500);
  };

  const handleClick = (clickSrc: string) => {
    updateSelectedSrc(clickSrc);
  };

  useEffect(() => {
    console.log(selectedSrc);
  }, [selectedSrc]);

  return (
    <div className=" w-screen border-red-500 max-h-[600px]">
      <div className="w-screen h-[600px] pointer-events-none">
        <img
          className={` w-full h-full object-cover -z-1 ${
            imageChanged ? "fade-in" : "fade-in-fade-out"
          }`}
          id="bg-img"
          src={backgroundImage}
          alt=""
        />
      </div>
      <div
        id="movies-card"
        className="flex gap-5 w-[87vw] mx-auto overflow-x-scroll items-center relative bottom-[250px] h-[230px] cursor-grab"
      >
        <div
          onClick={() =>
            handleChange(
              "https://www.figma.com/file/VQW4OWMDpW4QAqgvLsvjh6/image/aac6c3fef68772bd5687939f1234345af9f32064"
            )
          }
          className="min-w-[120px] h-[180px] flex flex-col items-center rounded-xl overflow-clip group relative"
        >
          <img
            className="h-full w-full object-cover"
            src="https://www.figma.com/file/VQW4OWMDpW4QAqgvLsvjh6/image/3dc22208cb29b6baa443860d13c60c2c35698418"
            alt=""
          />
          <button
            onClick={() =>
              handleClick(
                "https://www.figma.com/file/VQW4OWMDpW4QAqgvLsvjh6/image/3dc22208cb29b6baa443860d13c60c2c35698418"
              )
            }
            className=" absolute mt-32 rounded-full mx-auto text-center text-pretty w-[80px] h-[30px] -z-10 text-white text-sm bg-red-600 group-hover:z-40 duration-300 ease-in-out"
          >
            <Link className="no-underline text-white font-semibold" to="/Booking">Book Now</Link>
          </button>
        </div>
        <div
          onClick={() =>
            handleChange(
              "https://www.figma.com/file/VQW4OWMDpW4QAqgvLsvjh6/image/b2f9be30f2d248e4d85a22299f4c0eab87c159e5"
            )
          }
          className="min-w-[120px] h-[180px] flex flex-col items-center rounded-xl overflow-clip group relative"
        >
          <img
            className="h-full w-full object-cover"
            src="https://m.media-amazon.com/images/W/MEDIAX_792452-T1/images/S/pv-target-images/f5614be0a444c533b9f1e9b650518a872792a8d6d970c2c2823e24122f6177ab.jpg"
            alt=""
          />
          <button
            onClick={() =>
              handleClick(
                "https://m.media-amazon.com/images/W/MEDIAX_792452-T1/images/S/pv-target-images/f5614be0a444c533b9f1e9b650518a872792a8d6d970c2c2823e24122f6177ab.jpg"
              )
            }
            className=" absolute mt-32 rounded-full mx-auto text-center text-pretty w-[80px] h-[30px] -z-10 text-white text-sm bg-red-600 group-hover:z-40 duration-300 ease-in-out"
          >
            <Link className="no-underline text-white font-semibold" to="/Booking">Book Now</Link>
          </button>
        </div>
        <div
          onClick={() =>
            handleChange(
              "https://www.figma.com/file/VQW4OWMDpW4QAqgvLsvjh6/image/95fa4f7066220a81b1ae8140c787bed9ac9c76ae"
            )
          }
          className="min-w-[120px] h-[180px] flex flex-col items-center rounded-xl overflow-clip group relative"
        >
          <img
            className="h-full w-full object-cover"
            src="https://www.figma.com/file/VQW4OWMDpW4QAqgvLsvjh6/image/29c7aef2045fce8c5054f2e840205ea5a28c6968"
            alt=""
          />
          <button
            onClick={() =>
              handleClick(
                "https://www.figma.com/file/VQW4OWMDpW4QAqgvLsvjh6/image/29c7aef2045fce8c5054f2e840205ea5a28c6968"
              )
            }
            className=" absolute mt-32 rounded-full mx-auto text-center text-pretty w-[80px] h-[30px] -z-10 text-white text-sm bg-red-600 group-hover:z-40 duration-300 ease-in-out"
          >
            <Link className="no-underline text-white font-semibold" to="/Booking">Book Now</Link>
          </button>
        </div>
        <div
          onClick={() =>
            handleChange(
              "https://miro.medium.com/v2/resize:fit:1000/1*Nxaz2PmGHZYJv0gIcBOOWg.jpeg"
            )
          }
          className="min-w-[120px] h-[180px] flex flex-col items-center rounded-xl overflow-clip group relative"
        >
          <img
            className="h-full w-full object-cover"
            src="https://m.media-amazon.com/images/M/MV5BM2MyNjYxNmUtYTAwNi00MTYxLWJmNWYtYzZlODY3ZTk3OTFlXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_FMjpg_UX1000_.jpg"
            alt=""
          />
          <button
            onClick={() =>
              handleClick(
                "https://m.media-amazon.com/images/M/MV5BM2MyNjYxNmUtYTAwNi00MTYxLWJmNWYtYzZlODY3ZTk3OTFlXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_FMjpg_UX1000_.jpg"
              )
            }
            className=" absolute mt-32 rounded-full mx-auto text-center text-pretty w-[80px] h-[30px] -z-10 text-white text-sm bg-red-600 group-hover:z-40 duration-300 ease-in-out"
          >
            <Link className="no-underline text-white font-semibold" to="/Booking">Book Now</Link>
          </button>
        </div>
        <div
          onClick={() =>
            handleChange(
              "https://hebdenbridgepicturehouse.co.uk/images/3415.jpg"
            )
          }
          className="min-w-[120px] h-[180px] flex flex-col items-center rounded-xl overflow-clip group relative"
        >
          <img
            className="h-full w-full object-cover"
            src="https://m.media-amazon.com/images/M/MV5BNDUyNTIzNDQtYTZmMi00M2FlLTgyZjUtYWViZWNhMDYzMjE4XkEyXkFqcGdeQXVyMTUzMTg2ODkz._V1_.jpg"
            alt=""
          />
          <button
            onClick={() =>
              handleClick(
                "https://m.media-amazon.com/images/M/MV5BNDUyNTIzNDQtYTZmMi00M2FlLTgyZjUtYWViZWNhMDYzMjE4XkEyXkFqcGdeQXVyMTUzMTg2ODkz._V1_.jpg"
              )
            }
            className=" absolute mt-32 rounded-full mx-auto text-center text-pretty w-[80px] h-[30px] -z-10 text-white text-sm bg-red-600 group-hover:z-40 duration-300 ease-in-out"
          >
            <Link className="no-underline text-white font-semibold" to="/Booking">Book Now</Link>
          </button>
        </div>
        <div
          onClick={() =>
            handleChange(
              "https://www.figma.com/file/VQW4OWMDpW4QAqgvLsvjh6/image/b7d91709e4cec24ebf8935c61f14771c91776af1"
            )
          }
          className="min-w-[120px] h-[180px] flex flex-col items-center rounded-xl overflow-clip group relative"
        >
          <img
            className="h-full w-full object-cover"
            src="https://www.figma.com/file/VQW4OWMDpW4QAqgvLsvjh6/image/5c3cde3338160fce9898e55297bfc818e70763eb"
            alt=""
          />
          <button
            onClick={() =>
              handleClick(
                "https://www.figma.com/file/VQW4OWMDpW4QAqgvLsvjh6/image/5c3cde3338160fce9898e55297bfc818e70763eb"
              )
            }
            className=" absolute mt-32 rounded-full mx-auto text-center text-pretty w-[80px] h-[30px] -z-10 text-white text-sm bg-red-600 group-hover:z-40 duration-300 ease-in-out"
          >
            <Link className="no-underline text-white font-semibold" to="/Booking">Book Now</Link>
          </button>
        </div>
        <div
          onClick={() =>
            handleChange(
              "https://i.ytimg.com/vi/yT2Ms3LXhQ4/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLAB-5Ztu4LqVI6JdlJ2Ndm6gjCV0Q"
            )
          }
          className="min-w-[120px] h-[180px] flex flex-col items-center rounded-xl overflow-clip group relative"
        >
          <img
            className="h-full w-full object-cover"
            src="https://resizing.flixster.com/QrT-Krzry3Ikh6cH4u9bHCYH_6w=/ems.cHJkLWVtcy1hc3NldHMvdHZzZXJpZXMvNGZmZmJkN2YtZDA4YS00ZjkzLWE3OTMtNTgyNDE5NWM3YjhmLmpwZw=="
            alt=""
          />
          <button
            onClick={() =>
              handleClick(
                "https://resizing.flixster.com/QrT-Krzry3Ikh6cH4u9bHCYH_6w=/ems.cHJkLWVtcy1hc3NldHMvdHZzZXJpZXMvNGZmZmJkN2YtZDA4YS00ZjkzLWE3OTMtNTgyNDE5NWM3YjhmLmpwZw=="
              )
            }
            className=" absolute mt-32 rounded-full mx-auto text-center text-pretty w-[80px] h-[30px] -z-10 text-white text-sm bg-red-600 group-hover:z-40 duration-300 ease-in-out"
          >
            <Link className="no-underline text-white font-semibold" to="/Booking">Book Now</Link>
          </button>
        </div>
        <div
          onClick={() =>
            handleChange(
              "https://variety.com/wp-content/uploads/2014/02/vikings-tv-review.jpg?w=1000"
            )
          }
          className="min-w-[120px] h-[180px] flex flex-col items-center rounded-xl overflow-clip group relative"
        >
          <img
            className="h-full w-full object-cover"
            src="https://www.figma.com/file/VQW4OWMDpW4QAqgvLsvjh6/image/6e7edb96f27c24dc4d498ff77a384724003068a9"
            alt=""
          />
          <button
            onClick={() =>
              handleClick(
                "https://www.figma.com/file/VQW4OWMDpW4QAqgvLsvjh6/image/6e7edb96f27c24dc4d498ff77a384724003068a9"
              )
            }
            className=" absolute mt-32 rounded-full mx-auto text-center text-pretty w-[80px] h-[30px] -z-10 text-white text-sm bg-red-600 group-hover:z-40 duration-300 ease-in-out"
          >
            <Link className="no-underline text-white font-semibold" to="/Booking">Book Now</Link>
          </button>
        </div>
        <div
          onClick={() =>
            handleChange(
              "https://www.azcentral.com/gcdn/-mm-/116355459f42393ce503190990f7e8c7b2f34b2f/c=382-0-2458-1173/local/-/media/2016/01/27/Phoenix/Phoenix/635895019246814711-panda2.png?width=1600&height=800&fit=crop&format=pjpg&auto=webp"
            )
          }
          className="min-w-[120px] h-[180px] flex flex-col items-center rounded-xl overflow-clip group relative"
        >
          <img
            className="h-full w-full object-cover"
            src="https://m.media-amazon.com/images/M/MV5BMTUyNzgxNjg2M15BMl5BanBnXkFtZTgwMTY1NDI1NjE@._V1_FMjpg_UX1000_.jpg"
            alt=""
          />
          <button
            onClick={() =>
              handleClick(
                "https://m.media-amazon.com/images/M/MV5BMTUyNzgxNjg2M15BMl5BanBnXkFtZTgwMTY1NDI1NjE@._V1_FMjpg_UX1000_.jpg"
              )
            }
            className=" absolute mt-32 rounded-full mx-auto text-center text-pretty w-[80px] h-[30px] -z-10 text-white text-sm bg-red-600 group-hover:z-40 duration-300 ease-in-out"
          >
            <Link className="no-underline text-white font-semibold" to="/Booking">Book Now</Link>
          </button>
        </div>
        <div
          onClick={() =>
            handleChange(
              "https://survi.in/wp-content/uploads/2022/12/Avatar-2-Review.jpeg"
            )
          }
          className="min-w-[120px] h-[180px] flex flex-col items-center rounded-xl overflow-clip group relative"
        >
          <img
            className="h-full w-full object-cover"
            src="https://wallpapers.com/images/hd/avatar-2-the-way-of-water-wallpaper-x6e38pmgwy0rvi48.jpg"
            alt=""
          />
          <button
            onClick={() =>
              handleClick(
                "https://wallpapers.com/images/hd/avatar-2-the-way-of-water-wallpaper-x6e38pmgwy0rvi48.jpg"
              )
            }
            className=" absolute mt-32 rounded-full mx-auto text-center text-pretty w-[80px] h-[30px] -z-10 text-white text-sm bg-red-600 group-hover:z-40 duration-300 ease-in-out"
          >
            <Link className="no-underline text-white font-semibold" to="/Booking">Book Now</Link>
          </button>
        </div>
      </div>
      <Anime />
      <Animation />
      <About />
      <MovieNoose />
      <Footer />

    </div>
  );
};
export default Hero;
