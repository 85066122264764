import cartoonList from "./AnimationListArr";
import { useState } from "react";
import './App.css'

const Animation = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const ComponentList = (props: any) => {
    return (
      <div className="w-[250px] group h-[350px] mx-auto rounded-3xl hover:scale-110 duration-200 ease-in-out expanded lg:w-[180px] lg:h-[250px] sm:h-[180px] xs:w-[120px] xs:h-[120px]">
        <img
          className="w-full h-full group-hover:brightness-50 group-hover:transition-opacity duration-200 object-cover filter brightness-75 "
          src={props.img}
        />
        <div className="relative w-5/6 -top-32 text-white mx-auto pointer-events-none flex flex-col gap-y-2 xls:-top-28 xs:-top-26 xs:gap-y-0">
          <h2 className="text-xl shadow-sm relative font-sans font-medium xs:text-sm lg:text-base">
            {props.title}
          </h2>
          <p className="text-sm relative xs:text-xs">{props.date}</p>
          <p className="text-sm relative p-2 bg-gray-700/60 w-[25%] text-center rounded-md xs:text-[8px] xs:p-0 lg:p-1">
            {props.lang}
          </p>
        </div>
      </div>
    );
  };

  return (
    <section className=" mt-20 w-screen h-full flex justify-center">
      <div className="w-5/6 font-sans  text-2xl ">
        <div className="relative flex justify-between">
          <h2 className="xs:text-base">Animation Movies: -</h2>
          <p
            className="relative float-right text-lg hover:text-blue-700 hover:underline cursor-pointer xs:text-sm"
            onClick={toggleExpand}
          >
            {expanded ? "See" : "See"} {expanded ? "Less" : "More"}<i className="fa-solid fa-right-long ml-2"></i>
          </p>
        </div>
        <div className={`grid grid-cols-4 grid-rows-auto relative top-10 gap-y-8 ${expanded ? "expanded-grid" : ""} md:grid-cols-2`}>
          {cartoonList.slice(0, expanded ? cartoonList.length : 4).map((props) => (
            <ComponentList key={props.id} {...props} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Animation;
