export default [
    {
        id: 1,
        img: 'https://m.media-amazon.com/images/M/MV5BMTg5MzUxNzgxNV5BMl5BanBnXkFtZTgwMTM2NzQ3MjI@._V1_.jpg',
        title: 'The Boss Baby',
        lang: 'Eng',
        date: 'March 31, 2017',
    },
    {
        id: 2,
        img: 'https://m.media-amazon.com/images/M/MV5BMTUyNzgxNjg2M15BMl5BanBnXkFtZTgwMTY1NDI1NjE@._V1_FMjpg_UX1000_.jpg',
        title: 'Kung Fu Panda 3',
        lang: 'Eng',
        date: 'January 23, 2016',
    },
    {
        id: 3,
        img: 'https://m.media-amazon.com/images/M/MV5BMTAzMzI0NTMzNDBeQTJeQWpwZ15BbWU3MDM3NTAyOTM@._V1_FMjpg_UX1000_.jpg',
        title: 'Megamind',
        lang: 'Eng',
        date: 'November 5, 2010',
    },
    {
        id: 4,
        img: 'https://m.media-amazon.com/images/M/MV5BNTgwMjRlMDItMGIyNi00ZjJiLWEzNzgtZjI3OGRmZWU4YTUzXkEyXkFqcGdeQXVyMTA3MDk2NDg2._V1_.jpg',
        title: 'Tom & Jerry',
        lang: 'Eng',
        date: 'February 26, 2021',
    },
    {
        id: 5,
        img: 'https://m.media-amazon.com/images/M/MV5BMTY3NjY0MTQ0Nl5BMl5BanBnXkFtZTcwMzQ2MTc0Mw@@._V1_.jpg',
        title: 'Despicable Me',
        lang: 'Eng',
        date: 'July 9, 2010',
    },
    {
        id: 6,
        img: 'https://m.media-amazon.com/images/M/MV5BMTY3MjU0NDA0OF5BMl5BanBnXkFtZTgwNTc0MTU3OTE@._V1_.jpg',
        title: 'The Angry Birds Movie',
        lang: 'Eng',
        date: 'May 20, 2016',
    },
    {
        id: 7,
        img: 'https://m.media-amazon.com/images/M/MV5BNTE2YzljYzAtMzRlMi00ZmY1LThiMTMtZGQ3OTI3MTNmOWI2XkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_FMjpg_UX1000_.jpg',
        title: 'Madagascar Escape 2 Africa',
        lang: 'Eng',
        date: 'November 11, 2008',
    },
    {
        id: 8,
        img: 'https://m.media-amazon.com/images/M/MV5BMTQ1MjQwMTE5OF5BMl5BanBnXkFtZTgwNjk3MTcyMDE@._V1_.jpg',
        title: 'Frozen',
        lang: 'Eng',
        date: 'November 27, 2013',
    }
]