
import React from 'react'

const About = () => {
  return (
    <section className='w-screen shadow-sm relative top-28'>
        <div className=" h-[410px] w-5/6 mx-auto flex justify-between">
            <div className='w-1/2 self-center'>
                <h2 className='font-bold font-sans text-xl text-[#383838]'>About Us</h2>
                <p className='text-sm font-light'>2005-2024</p>
                <p className='text-[#5d5d5d] font-sans font-medium pt-6 w-3/4 md:text-sm xs:text-xs'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Harum quae ipsum corporis, officia beatae laborum nihil nobis ex itaque assumenda quas ad dolores sit quasi vero earum iusto animi tempore?Lorem ipsum, dolor sit amet consectetur adipisicing elit. Harum quae ipsum corporis, officia beatae laborum nihil nobis ex itaque assumenda quas ad dolores sit quasi vero earum iusto animi tempore?</p>
            </div>
            <div className='w-1/2 h-[405px] pointer-events-none rounded-lg overflow-clip'>
                <img className='w-full h-full object-cover' src="https://www.figma.com/file/VQW4OWMDpW4QAqgvLsvjh6/image/adf74f28fd6acfe4308fdcd5a16103e28234b5e0" alt="" />
            </div>
        </div>
    </section>
  )
}

export default About